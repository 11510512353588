<template>
  <div class="main-body">
    <a-form :model="formState" :label-col="labelCol" :wrapper-col="wrapperCol">
      <a-form-item label="注册方式">
        <a-checkbox-group v-model:value="formState.register">
          <a-checkbox value="1" name="register">账号</a-checkbox>
          <a-checkbox value="2" name="register">手机号</a-checkbox>
        </a-checkbox-group>
      </a-form-item>
      <a-form-item label="密码长度" extra="新用户注册时密码最小长度，0或不填为不限制">
        <a-input v-model:value="formState.password_length" placeholder="请输入密码最小长度" />
      </a-form-item>
      <a-form-item label="密码复杂度">
        <a-checkbox-group v-model:value="formState.password_complexity">
          <a-checkbox value="1" name="register">数字</a-checkbox>
          <a-checkbox value="2" name="register">小写字母</a-checkbox>
          <a-checkbox value="3" name="register">大写字母</a-checkbox>
          <a-checkbox value="4" name="register">符号</a-checkbox>
        </a-checkbox-group>
      </a-form-item>
      <a-form-item :wrapper-col="{ span: 7, offset: 3 }">
        <a-button type="primary" @click="onSubmit">保存</a-button>
      </a-form-item>
    </a-form>
  </div>
</template>
<script>
import { defineComponent, reactive, onMounted } from "vue";
import * as Api from "@/views/setting/api";
import { message } from "ant-design-vue";

export default defineComponent({
  components: {},
  setup() {
    const formState = reactive({
      register: [],
      password_length: 0,
      password_complexity: [],
    });

    const onSubmit = () => {
      Api.save_setting(formState).then((res) => {
        message.success(res.message);
      });
    };

    const getInitSetting = () => {
      Api.get_global_setting().then((res) => {
        formState.register = res.data.register ? res.data.register : [];
        formState.password_length = res.data.password_length
          ? res.data.password_length
          : 0;
        formState.password_complexity = res.data.password_complexity
          ? res.data.password_complexity
          : [];
      });
    };

    onMounted(() => {
      getInitSetting();
    });

    return {
      labelCol: {
        span: 3,
      },
      wrapperCol: {
        span: 7,
      },
      formState,
      onSubmit,
    };
  },
});
</script>